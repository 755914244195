<template>
  <Panel header="Login" id="login-painel">
    <div class="p-fluid">
      <div class="p-field">
        <label for="email" class="p-sr-only">E-mail</label>
        <InputText
          id="email"
          type="text"
          placeholder="E-mail"
          v-model="email"
          @keyup="enterLogin"
        />
      </div>
      <div class="p-field">
        <label for="senha" class="p-sr-only">Senha</label>
        <InputText
          id="senha"
          type="password"
          placeholder="Senha"
          v-model="senha"
          @keyup="enterLogin"
        />
      </div>
      <div>
        <Button
          type="button"
          label="autenticar"
          icon="pi pi-check"
          @click="login"
        />
      </div>
    </div>
  </Panel>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      senha: "",
    };
  },
  methods: {
    enterLogin: function(e) {
      if(e.code != "Enter")
        return;
      if(e.target.id == "email") {
        document.getElementById("senha").focus();
      } else {
        this.login();
      }
    },
    login: function () {
        const self = this;
        this.$auth.logged({
            "email" : this.email,
            "password" : this.senha,
            "device_name" : "Web"
        }, function(response) {
            if(response.success) {
                self.$router.push('/');
            } else {
                alert(response.message);
            }
        });
    },
  },
};
</script>

<style scoped>

#login-painel {
  width: 400px;
  position: absolute;
  left: 50%;
  margin-left: -200px;
  top: 50%;
  margin-top: -100px;
}

.p-panel :deep(.p-panel-header) {
  border-color: #01314e;
  background-color: #01314e;
  color: white;
}

.p-button {
  border-color: #f47920;
  background-color: #f47920;
}

.p-button:enabled:active {
    background: #d0661a;
    border-color: #d0661a;
}

.p-button:enabled:hover {
    background: #ff8228;
    border-color: #ff8228;
}

</style>